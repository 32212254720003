import ResponsiveEmbed from "react-responsive-embed";

export default function MyFileUpload() {
  return (
    <>
      <br />
      <br />
      <div className="file-upload"
      style={
        {marginLeft: "auto", marginRight: "auto", width: "90%"}
      }>
        <ResponsiveEmbed
          src="https://imagemark.app.box.com/f/30acb895f50c4e6e80879b17349ae03a"
          ratio="4:4.75"
        />
        <br/>
      </div>
    </>
  );
}
