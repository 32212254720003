import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";
import MyTextInput from "../components/MyTextInput";
import MyCheckbox from "../components/MyCheckbox";
import MySelect from "../components/MySelect";
import MyDateInput from "components/MyDateInput";
import AnyErrors from "../components/AnyErrors";
import ChangeRequest from "./ChangeRequest";
import NewItemRequest from "./NewItemRequest";
import axios from "axios";
import { useState } from "react";
import MyFileUpload from "../components/MyFileUpload";
const submitURL = "https://submit-form.com/9AM5VhS3";
export default function MainForm() {
  const [submitState, setSubmitState] = useState(false);
  const [fileUploadShown, setFileUploadShown] = useState(false);
  
  function getDateInFuture(days) {
    const today = new Date();
    const future = new Date();
    future.setDate(today.getDate() + days);
    // set the time to midnight
    future.setHours(0, 0, 0, 0);
    return future;
  }

  return (
    <>
      <div className="container">
        {/* if submitState is true, then show the success message, else, show the form */}
        {submitState ? (
          fileUploadShown ? (
            <center>
              <style>
                {`
                .button.return {
                  position: unset;
                  transition: all 0.5s ease;
                }`}
              </style>
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Thank you for your submission.</h4>
            <p>We will contact you when the change you requested is complete.</p>
            <hr />
            <p className="mb-0">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSubmitState(false);
                }}
              >
                Submit another form?
              </button>
            </p>
          </div></center>) : (
            <center>
            <div className="alert alert-success" role="alert">
              <h4 className="alert alert-info">Please submit your files.</h4>
              </div>
              <MyFileUpload/>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setFileUploadShown(true);
                }}
              >
                Submit Request
              </button>
            </center>
          )
        ) : (
          <Formik
            initialValues={{
              email : "",
              mainFolder: "",
              changeRequest: "",
              changeDescription: "",
              productName: "",
              newLocation: "",
              folderName: "",
              city: "",
              state: "",
              productType: "",
              newSize: "",
              size: "",
              cutLineConfirmation: "",
              maxZoneLength: "",
              dateNeeded: "",
            }}
            validationSchema={Yup.object({
              // validate email
              email: Yup.string()
                .email("Invalid email address")
                .required("An email address is required."),
              // validate mainFolder
              mainFolder: Yup.string().required("Please select a folder."),
              changeRequest: Yup.string()
                // require a non-empty string
                .required("Required"),
              changeDescription: Yup.string().when("changeRequest", {
                is: (val) => val === "true",
                then: Yup.string().required("Please describe the requested change."),
              }),
              newLocation: Yup.string()
                // require if changeRequest is "false"
                .when("changeRequest", {
                  is: (val) => val === "false",
                  then: Yup.string().required("Please indicate if this is a new item, or an existing item."),
                }),
              folderName: Yup.string()
                // require if changeRequest is "false"
                .when("changeRequest", {
                  is: (val) => val === "false",
                  then: Yup.string().required("Please enter the name of the folder."),
                }),
              city: Yup.string()
                //require if newLocation is "true"
                .when("newLocation", {
                  is: (val) => val === "true",
                  then: Yup.string().required("Please enter the city."),
                }),
              state: Yup.string()
                //require if newLocation is "true"
                .when("newLocation", {
                  is: (val) => val === "true",
                  then: Yup.string().required("Please enter the state."),
                }),
              //cutLineConfirmation is a checkbox that must be checked if newSize is "true"
              cutLineConfirmation: Yup.bool()
                // require if newSize is "true"
                .when("newSize", {
                  is: (val) => val === "true",
                  then: Yup.bool().oneOf(
                    [true],
                    "You must include a cut line for a product with a new form factor. Make sure you've updated your file before uploading."
                  ),
                }),
              productType: Yup.string()
                //require
                .required("Please select a product type."),
              size: Yup.string()
                //require
                .required("Please enter the size."),
              newSize: Yup.string()
                //require
                .required("Please indicate if this is a new size."),
              // dateNeeded is a date that must be at least 3 days in the future
              dateNeeded: Yup.date()
                .min(getDateInFuture(3), "Must be at least 3 days in the future.")
                .required("Please enter a date."),
              maxZoneLength: Yup.string().required("Please enter the maximum zone length."),
              productName: Yup.string()
                // require if changeRequest is "true"
                .when("changeRequest", {
                  is: (val) => val === "true",
                  then: Yup.string().required("Please enter the product name."),
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                // submit to submitURL with values
                axios
                  .post(submitURL, values)
                  .then((res) => {
                    console.log(res);
                    console.log(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                setSubmitting(false);
                setSubmitState(true);
              }, 400);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
              } = props;
              return (
                <Form>
                  <MyTextInput
                    label="Email Address"
                    name="email"
                    type="email"
                  />
                  <MySelect label="Main Folder Location" name="mainFolder">
                    <option value="">Select an Option</option>
                    <option value="Open Eco">Open Eco</option>
                    <option value="Passport">Passport</option>
                    <option value="Private Label">Private Label</option>
                  </MySelect>
                  <MySelect label="Is this a new item?" name="changeRequest">
                    <option value="">Select an Option</option>
                    <option value="false">Yes</option>
                    <option value="true">No</option>
                  </MySelect>
                  {
                    /* if the changeRequest value is true, show <ChangeRequest /> */
                    (values.changeRequest === "true" && (
                      <>
                        <ChangeRequest {...props} />
                      </>
                    )) ||
                      /* if the changeRequest value is false, show <NewItemRequest /> */
                      (values.changeRequest === "false" && (
                        <>
                          <NewItemRequest {...props} />
                        </>
                      ))
                  }{" "}
                  <MySelect label="Product Type" name="productType">
                    <option value="">Select an Option</option>
                    <option value="ACM">ACM Sign</option>
                    <option value="Aluminum">Aluminum Sign</option>
                    <option value="Decal">Decal</option>
                    <option value="Marketing Collateral">Marketing Collateral</option>
                  </MySelect>
                  <MyTextInput label="Size" name="size" />
                  <MySelect
                    label="Does this product have a unique size or shape?"
                    name="newSize"
                  >
                    <option value="">Select an Option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </MySelect>
                  {values.newSize === "true" && (
                    <>
                      <label htmlFor="cutLineConfirmation">
                        The art for this item has cut lines added.
                      </label>
                      <MyCheckbox name="cutLineConfirmation" />
                    </>
                  )}
                  <MyTextInput
                    label="Maximum Zone Number Length"
                    name="maxZoneLength"
                  />
                  <label htmlFor="additionalNotes">
                    If there is anything unusual about this request, please
                    describe:
                  </label>
                  <Field
                    as="textarea"
                    name="additionalNotes"
                    label="additionalNotes"
                  />
                  
                  {/* date needed is a date that must be at least 3 days in the future */}
                  <MyDateInput label="Date Needed" name="dateNeeded" min={getDateInFuture(3)} />
                  {Object.keys(errors).length > 0 && (
                    <>
                    <center>
                      <div className="error-message">
                        Please correct the indicated errors before submitting.
                      </div>
                    </center>
                    </>
                  )}
                  <center>
                    <button type="submit">Next</button>
                    <AnyErrors />
                  </center>
                  {/*<DisplayFormikState {...props} />*/}
                </Form>
              );
            }}
          </Formik>
        )}
        {/* button for returning to the home page */}
        <br />
        <br />
        <center>
          <button
            className="button return"
            onClick={() => {
              window.location.href = "http://passport-r1.com";
            }}
          >
            Return to Passport ResourceONE
          </button>
        </center>
      </div>
    </>
  );
}
