import MySelect from "../components/MySelect";

import NewLocation from "./NewLocation";
import ExistingLocation from "./ExistingLocation";

const NewItemRequest = (props) => {
  const {
    values,
  } = props;
  return (
    <>
      <MySelect label="Is this a new location?" name="newLocation">
        <option value="">Select an Option</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </MySelect>
      {(values.newLocation === "true" && (
        <>
          <NewLocation {...props} />
        </>
      )) ||
        (values.newLocation === "false" && (
          <>
            <ExistingLocation {...props} />
          </>
        ))}
    </>
  );
};

export default NewItemRequest;
