import "./App.css";
import MainForm from "./form_variants/MainForm";

function App() {
  return (
    <>
      <center>
        <h1>ResourceONE Request Form</h1>
      </center>
      <MainForm />
    </>
  );
}

export default App;
