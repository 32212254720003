import React from "react";
import { useField } from "formik";

export default function MyDateInput({ label, min, ...props }) {
  const [field, meta] = useField(props);
  // convert min to a YYYY-MM-DD string
  const minDate = min ? min.toISOString().substring(0, 10) : null;
  return (
    <div className="formInput">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} type="date"
      min={minDate}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}
