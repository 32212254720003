import MyTextInput from "../components/MyTextInput";

const NewLocation = (props) => {
  return (
    <>
      <MyTextInput label="Name of New Folder" name="folderName" />
      <MyTextInput label="City" name="city" />
      <MyTextInput label="State" name="state" />
    </>
  );
};

export default NewLocation;
