import { Field } from "formik";
import MyTextInput from "../components/MyTextInput";

const ChangeRequest = (props) => (
  <>
    <MyTextInput label="Name of Existing Product" name="productName" />
    {/* make a textarea for the description */}
    <br />
    <label htmlFor="changeDescription">Description of Change</label>
    <Field as="textarea" name="changeDescription" label="Description" />
    {props.errors.changeDescription ? (
      <div className="error">{props.errors.changeDescription}</div>
    ) : null}
  </>
);

export default ChangeRequest;
