import MyTextInput from "../components/MyTextInput";

const ExistingLocation = (props) => {
  return (
    <>
      <MyTextInput label="Name of Existing Folder" name="folderName" />
    </>
  );
};

export default ExistingLocation;
